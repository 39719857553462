import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const applicationAPI = {
  getApplication: params => getNoCatch(`${topic.applicationTopic}/getInspecationprod`, params),
  deleteApplicationByIds: params => postNoCatch(`${topic.applicationTopic}/deleteInspecationprod`, params),
  addInspecationprod: params => postNoCatch(`${topic.applicationTopic}/addInspecationprod`, params),
  editInspecationprod: params => postNoCatch(`${topic.applicationTopic}/editInspecationprod`, params),
  getApplicationById: params => getNoCatch(`${topic.applicationTopic}/getInspecationprodById`, params),
  editInspecationprodStart: params => getNoCatch(`${topic.applicationTopic}/editInspecationprodStart`, params)
};
